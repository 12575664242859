import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ToasterService } from '../../services/toaster.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-media-modal',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  providers:[TranslatePipe],
  templateUrl: './media-modal.component.html',
  styleUrl: './media-modal.component.scss',
})
export class MediaModalComponent implements OnDestroy, AfterViewInit {
  @Input() mediaType = 'all';
  @ViewChild('videoElement') videoElement?: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  photo: {
    name: string;
    type: string;
    size: number;
    preview: string;
    file: File;
    isVideo: boolean;
  } | null = null;
  @Output() capturedContent = new EventEmitter<{
    name: string;
    type: string;
    size: number;
    preview: string;
    file: File;
    isVideo: boolean;
  }>();
  @Output() closeModal = new EventEmitter<boolean>();
  private stream!: MediaStream;
  @ViewChild('downloadLink') downloadLink!: ElementRef<HTMLAnchorElement>;
  videoWidth = 0;
  videoHeight = 0;
  isFrontCamera: boolean = true; // Flag to track the active camera (front or back)
  //video
  @ViewChild('videoRecElement', { static: false }) videoRecElement!: ElementRef;
  @ViewChild('recordedVideo', { static: false }) recordedVideo!: ElementRef;
  mediaRecorder!: MediaRecorder;
  recordedChunks: any[] = [];
  isRecording = false;
  videoUrl = '';
  isCamera = true;
  isVideo = false;
  public recordingTime: string = '00:00:00';
  private startTime!: number;
  private timerInterval!: any;
  backCameraExist= false;
  constructor(private toaster: ToasterService, private cdr: ChangeDetectorRef, private translatePipe: TranslatePipe) {}
  ngAfterViewInit(): void {
    this.getAvailableDevices();
    this.startCamera();
    if (this.mediaType === 'video'){
      this.startVideo();
      this.cdr.detectChanges();
    }
  }

  startCamera(): void {
    const constraints = {
      video: {
        facingMode: this.isFrontCamera ? 'user' : { exact: 'environment' }
      }
    };
    this.cdr.detectChanges();
    this.isCamera = true;
    this.isVideo = false;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.stream = stream;
        if (this.videoElement) {
          this.videoElement.nativeElement.srcObject = stream;
        }
        // this.videoElement.nativeElement.srcObject = stream;
      })
      .catch((error) => {
        this.toaster.error(error,this.translatePipe.transform("Error"));
      });
  }
  
  capturePhoto(): void {
    if (this.videoElement != null) {
      const video = this.videoElement.nativeElement;
      const canvas = this.canvasElement.nativeElement;
      const context = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const file = new File([blob], 'captured_photo.png', {
            type: 'image/png',
          });
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.photo = {
              name: file.name,
              type: file.type,
              size: file.size,
              preview: e.target.result,
              file: file,
              isVideo: false,
            };
            console.log(this.photo)
            this.capturedContent.emit(this.photo);
          };
          reader.readAsDataURL(file);
        }
      }, 'image/png');
    }
  }
  submit() {
    this.capturedContent.emit(this.photo!);
    this.photo = null;
  }
  close() {
    this.closeModal.emit(true);
  }

  // Video
  startVideo(): void {
    this.isVideo = true;
    this.isCamera = false;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'user' }, audio: true })
      .then((stream) => {
        this.videoRecElement.nativeElement.srcObject = stream;
      })
      .catch((err) => {
        this.toaster.error(err,this.translatePipe.transform("Error"));
      });
  }
  startRecording(): void {
    this.videoUrl = '';
    const videoStream = this.videoRecElement.nativeElement.srcObject;
    this.mediaRecorder = new MediaRecorder(videoStream);
    this.recordedChunks = [];

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

    this.mediaRecorder.onstop = () => {
      const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
      this.videoUrl = URL.createObjectURL(blob);
      const file = new File([blob], 'captured_photo.webm', {
        type: 'video/webm',
      });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        let content = {
          name: file.name,
          type: file.type,
          size: file.size,
          preview: e.target.result,
          file: file,
          isVideo: true,
        };
        this.capturedContent.emit(content);
      };
      reader.readAsDataURL(file);
      this.checkVideoLength();
    };

    this.mediaRecorder.start();
    this.isRecording = true;
    this.startTimer();
    // this.recordingTimeout = setTimeout(() => {
    //   this.toaster.warning(this.translatePipe.transform("RecordingTimeLimit"));
    //   this.stopRecording();
    // }, 10000);
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.stopTimer();
    }
  }

  checkVideoLength() {
    const videoElement = document.createElement('video');
    videoElement.src = this.videoUrl;
    videoElement.addEventListener('canplaythrough', () => {
      const videoLength = videoElement.duration / 60; // duration in minutes
      console.log("Video Length:"+ videoLength);
    });
  }
  startTimer(): void {
    this.startTime = Date.now();
    this.timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - this.startTime;
      this.recordingTime = this.formatTime(elapsedTime);
      // if(this.recordingTime > )
    }, 1000);
  }
  formatTime(milliseconds: number): string {
    const minutes = Math.floor(milliseconds / 60000);
    const totalSeconds = Math.floor((milliseconds % 60000) / 1000);
    if (minutes == 5) {
      this.toaster.warning(this.translatePipe.transform("RecordingTimeLimit"),this.translatePipe.transform("Warning"));
      this.stopRecording();
    }
    const seconds = totalSeconds % 60;
    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  stopTimer(): void {
    clearInterval(this.timerInterval);
    this.recordingTime = '00:00';
  }
  switchCamera() {
    this.isFrontCamera = !this.isFrontCamera;
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }

    this.startCamera();
  }
  stopStream(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      if(this.videoRecElement){
        this.videoRecElement.nativeElement.srcObject = null;
      }      
      // this.videoElement.nativeElement.srcObject = null;
      // this.videoElement.nativeElement.srcObject = null;
    }
  }
  getAvailableDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let videoDevices = devices.filter(device => device.kind === 'videoinput');
      const backCameraExist= videoDevices.find(device => device.label.toLowerCase().includes('back'));
      if(backCameraExist){
        this.backCameraExist = true;
      }
      else{
        this.backCameraExist = false;
      }
    }).catch((err) => {
      console.error('Error getting devices: ', err);
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    this.recordingTime = '00:00';
    this.stopStream();
  }
}
