import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Caddy, CaddyListResponse } from "../model/caddy.model";
import { Observable, of, tap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class CaddyService {
  apiUrl = environment.apiUrl + 'caddy';
  private cachedData: CaddyListResponse  | undefined = undefined;
  constructor(private _httpClient: HttpClient) {}

  getAll(
    name: string = '',
    code: string = '',
    sortBy: string = '',
    limit: number = 30,
    page: number = 1
  ): Observable<CaddyListResponse> {
    // Check if cached data is available
    if (this.cachedData) {
      return of(this.cachedData); // Wrap the cached data in an observable
    }

    // If no cached data, call the API
    return this._httpClient.get<CaddyListResponse>(this.apiUrl, {
      params: {
        // Uncomment these lines if you want to use the parameters
        // name: name,
        // code: code,
        // sortBy: sortBy,
        limit: limit,
        page: page,
      },
    }).pipe(
      tap((response) => {
        this.cachedData = response; // Cache the response
      })
    );
  }
  create(input: Caddy) {
    return this._httpClient.post<any>(this.apiUrl, input);
  }
  update(input: Caddy, id: string){
    return this._httpClient.put(this.apiUrl+"/"+ id, input);
  }
  delete(id: string){
    return this._httpClient.delete(this.apiUrl+"/"+ id);
  }
}