<div class="modal-dialog cameraModal modal-dialog-centered modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-body">
      <video *ngIf="!isVideo" #videoElement width="100%" autoplay></video>
      <ng-container *ngIf="isCamera">
        <canvas #canvasElement width="100%" style="display: none"></canvas>
      </ng-container>
      <ng-container *ngIf="isVideo">
        <video *ngIf="!videoUrl" #videoRecElement width="100%" [playsInline]="true" autoplay [muted]="true"></video>
      </ng-container>
    </div>
    <div class="recording-time mb-3" *ngIf="isRecording">
      {{ recordingTime }}
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="!isRecording">
        @if (mediaType === 'image' || mediaType === 'all') {
          <a href="javascript:void(0)" (click)="startCamera()" [ngClass]="isCamera ? 'active': ''">
            <img src="assets/images/cameraFillIcon.svg" *ngIf="isCamera" alt="" class="white">
            <img src="assets/images/cameraFillBlack.svg" *ngIf="!isCamera" alt="" class="black">
          </a>
        }
        @if (mediaType === 'video' || mediaType === 'all') {
          <a href="javascript:void(0)" [ngClass]="isVideo ? 'active': ''" (click)="startVideo()">
            <img src="assets/images/videoWhiteIcon.svg" *ngIf="isVideo" alt="" class="white">
            <img src="assets/images/videoBlackIcon.svg" *ngIf="!isVideo" alt="" class="black">
          </a>
        }
      </ng-container>
      <div class="clickBg" *ngIf="!isRecording" (click)="isCamera ? capturePhoto() : startRecording()">
        <span class="clickBtn"></span>
      </div>
      <div class="clickBg" *ngIf="isRecording" (click)="stopRecording()">
        <span class="clickBtn"></span>
      </div>
      <button class="switch-camera" *ngIf="backCameraExist" toolTip="Switch camera" (click)="switchCamera()">
        <img src="assets/images/reverse_camera.png" alt="Switch Camera">
      </button>
      <div class="close" data-bs-dismiss="modal" *ngIf="!isRecording" (click)="close()">
        <img src="assets/images/closeBlack.svg" alt="">
      </div>
    </div>
  </div>
</div>