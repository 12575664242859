import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CreateOrEditLanguage, GetLanguageInput, LanguageListResponse } from "../model/language.model";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    apiUrl = environment.apiUrl + "language";
    constructor(private _httpClient: HttpClient) {

    }
    getAll(name = "", code = "", sortBy = "", limit = 10, page = 1) {
        return this._httpClient.get<LanguageListResponse>(this.apiUrl, {
            params: {
                // name: name ?? "",
                // code : code ?? "",
                // sortBy: sortBy ?? "",
                limit: limit,
                page: page
            }
        });
    }
    create(input: CreateOrEditLanguage) {
        return this._httpClient.post(this.apiUrl, input)
    }
    update(input: CreateOrEditLanguage, id: string) {
        return this._httpClient.put(this.apiUrl + id, input)
    }
    delete(id: string) {
        return this._httpClient.delete(this.apiUrl + id);
    }
}